import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { IAssociations } from "./AuthorizationService/associations.types";

interface GetGroupUsersParams {
  country?: string;
  app: string;
  group: string;
}

interface GetGroupUsersResponse {
  name: string;
  email: string;
  userId: string;
  application: string;
  country: string;
}
interface RemoveUserFromGroupParams {
  userId: string;
  groupName: string;
  targetUserCountry: string;
}

enum AuthorizationTypes {
  "APP" = "APP",
  "GROUP" = "GROUP",
  "ROLE" = "ROLE",
  "SCOPE" = "SCOPE",
}
export interface IGetAssociationParams {
  app: string;
  level: AuthorizationTypes;
  type: AuthorizationTypes;
  value: string;
  tree?: boolean;
  showDescription?: boolean;
}

export enum ScopeType {
  FRONT = "FRONT",
  BACK = "BACK",
}
export interface UpdateScopePayload {
  action: string;
  object: string;
  app: string;
  type: ScopeType;
  description?: string;
}

export interface CreateScopePayload {
  action: string;
  app: string;
  description?: string;
  object: string;
  type: ScopeType;
}

export interface IExtractUserListInput {
  options: string[];
  appName: string;
  groupName: string;
}

export class BffService {
  private readonly axiosInstance = axios.create({
    headers: {
      Authorization: localStorage.getItem("authHeader"),
    },
  });

  private readonly baseUrl = process.env.API_BASE_URL || "/api/identity-bff/v2";

  async getGroupUsers(
    params: GetGroupUsersParams
  ): Promise<GetGroupUsersResponse[]> {
    const { country, app, group } = params;

    const { data } = await this.axiosInstance.get(
      `${this.baseUrl}/roles-and-permissions/group-users-list`,
      {
        headers: {
          requestTraceId: uuidv4(),
        },
        params: {
          country,
          app,
          group,
        },
      }
    );

    return data;
  }

  async removeUserFromGroup({
    userId,
    groupName,
    targetUserCountry,
  }: RemoveUserFromGroupParams): Promise<number> {
    const { status } = await this.axiosInstance.delete(
      `${this.baseUrl}/roles-and-permissions/user-from-group`,
      {
        headers: {
          requestTraceId: uuidv4(),
        },
        params: {
          userId,
          groupName,
          targetUserCountry,
        },
      }
    );

    return status;
  }

  async updateScope(payload: UpdateScopePayload) {
    return await this.axiosInstance.put(
      `${this.baseUrl}/authorization/scopes`,
      payload,
      {
        headers: {
          requestTraceId: uuidv4(),
        },
      }
    );
  }

  async createScope(payload: CreateScopePayload): Promise<number | undefined> {
    const { status } = await this.axiosInstance.post(
      `${this.baseUrl}/authorization/scopes`,
      payload,
      {
        headers: {
          requestTraceId: uuidv4(),
        },
      }
    );
    return status;
  }

  async getAssociations(params: IGetAssociationParams): Promise<IAssociations> {
    const { data } = await this.axiosInstance.get(
      `${this.baseUrl}/authorization/associations`,
      { headers: { requestTraceId: uuidv4() }, params }
    );
    return data;
  }

  async getUnbound(app: string): Promise<IAssociations["links"]["unbound"]> {
    try {
      const { data } = await this.axiosInstance.get(
        `${this.baseUrl}/authorization/unbound`,
        { headers: { requestTraceId: uuidv4() }, params: { app } }
      );
      const { roles, scopes, groups } = data || {
        roles: {},
        scopes: {},
        groups: {},
      };
      return { ...roles, ...scopes, ...groups };
    } catch (error) {
      return {};
    }
  }

  async extractUserList(
    payload: IExtractUserListInput
  ): Promise<number | undefined> {
    try {
      const response = await this.axiosInstance.post(
        `${this.baseUrl}/roles-and-permissions/export-users`,
        {
          options: payload.options,
          app: payload.appName,
          group: payload.groupName,
        },
        {
          headers: {
            requestTraceId: uuidv4(),
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `user_list_${payload.appName}.${payload.groupName}.csv`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      return response.status;
    } catch (error) {
      return undefined;
    }
  }
}

export function useBFFService() {
  return new BffService();
}
