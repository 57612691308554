import { useUserMetadata } from "admin-portal-shared-services";
import Permissions from "identity-admin-mfe/modules/permission-management";
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Spinner } from "supplier-portal-shared-components";

const PermissionsPage = lazy(() => import("./pages/Permissions/Permissions"));

const PermissionsManagement = lazy<any>(
  () => import("identity-admin-mfe/modules/permission-management")
);

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
        <Switch>
          <Route exact path="/permissions" component={PermissionsPage} />
          <PermissionsManagement />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
export default Router;
